exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-casestudy-jsx": () => import("./../../../src/pages/casestudy.jsx" /* webpackChunkName: "component---src-pages-casestudy-jsx" */),
  "component---src-pages-comingsoon-jsx": () => import("./../../../src/pages/comingsoon.jsx" /* webpackChunkName: "component---src-pages-comingsoon-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-meet-brian-jsx": () => import("./../../../src/pages/meet-brian.jsx" /* webpackChunkName: "component---src-pages-meet-brian-jsx" */),
  "component---src-pages-old-index-jsx": () => import("./../../../src/pages/old-index.jsx" /* webpackChunkName: "component---src-pages-old-index-jsx" */),
  "component---src-pages-prismic-portfolio-url-jsx": () => import("./../../../src/pages/{PrismicPortfolio.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-portfolio-url-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

